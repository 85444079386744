<template>
  <div>
    <b-card>
      <div>
        <h3>
          Editar fornecedor
        </h3>
      </div>
    </b-card>
    <b-card>
      <div class="d-flex justify-content-between">
        <div>
          <h4 class="mb-25">
            Informações básicas
          </h4>
          <p class="small mb-0 mt-25">
            <i>
              Os códigos de registro interno do sistema e registro interno Tiny são preenchidos automaticamente.
            </i>
          </p>
        </div>
        <div class="mr-2">
          <b-button
            :variant="form.situation === 'A' ? 'outline-success' : 'outline-danger'"
            class="btn-icon rounded-circle"
            @click="form.situation = form.situation === 'A' ? 'I' : 'A'"
          >
            <feather-icon icon="PowerIcon" />
          </b-button>
        </div>
      </div>
      <hr class="mt-0">
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Registro interno do sistema"
            label-for="id"
          >
            <b-form-input
              id="id"
              v-model="form.id"
              placeholder="Código de registro interno tiny"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Código interno Romildo"
            label-for="internalId"
          >
            <b-form-input
              id="internalId"
              v-model="form.internalId"
              placeholder="Código interno da loja"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Registro interno Tiny"
            label-for="tinyId"
          >
            <b-form-input
              id="tinyId"
              v-model="form.tinyId"
              placeholder="Código de registro interno tiny"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Código"
            label-for="code"
          >
            <b-form-input
              id="code"
              v-model="form.code"
              placeholder="Código do fornecedor"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Razão Social"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="form.name"
              placeholder="Razão Social"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Nome"
            label-for="fantasyName"
          >
            <b-form-input
              id="fantasyName"
              v-model="form.fantasyName"
              placeholder="Nome do Fornecedor"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Documento"
            label-for="document"
          >
            <b-form-input
              id="document"
              v-model="form.document"
              placeholder="Documento"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Tipo"
          >
            <div class="d-flex">
              <b-form-checkbox
                v-model="form.type"
                value="J"
                class="custom-control-warning"
              >
                PJ
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.type"
                value="F"
                class="custom-control-info ml-2"
              >
                PF
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h4 class="mb-25">
        Registros fiscais
      </h4>
      <p class="small mb-0 mt-25">
        <i>
          Os registros fiscais não são obrigatórios para registro, mas indicamos preencher.
        </i>
      </p>
      <hr class="mt-0">
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Tipo de inscrição"
            label-for="typeIe"
          >
            <v-select
              id="typeIe"
              v-model="form.ie"
              label="text"
              :options="typesIe"
              placeholder="Selecione o tipo de inscrição"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Inscrição Estadual"
            label-for="rg"
          >
            <b-form-input
              id="rg"
              v-model="form.rg"
              placeholder="Inscrição Estadual"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Inscrição municipal"
            label-for="im"
          >
            <b-form-input
              id="im"
              v-model="form.im"
              placeholder="Inscrição municipal"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h4 class="mb-25">
        Contato
      </h4>
      <hr>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Telefone"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="form.phone"
              placeholder="Telefone"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Celular"
            label-for="cell"
          >
            <b-form-input
              id="cell"
              v-model="form.cell"
              placeholder="Celular"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Fax"
            label-for="fax"
          >
            <b-form-input
              id="fax"
              v-model="form.fax"
              placeholder="Fax"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="E-Mail"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="form.email"
              placeholder="E-Mail"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="E-Mail para envio da nfe"
            label-for="emailNfe"
          >
            <b-form-input
              id="emailNfe"
              v-model="form.emailNfe"
              placeholder="E-Mail para envio da nfe"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h4>
        Endereço
      </h4>
      <b-row>
        <b-col md="2">
          <b-form-group
            label="CEP"
            label-for="zipCode"
          >
            <b-form-input
              id="zipCode"
              v-model="form.zipCode"
              placeholder="CEP"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Pais"
            label-for="country"
          >
            <b-form-input
              id="country"
              v-model="form.country"
              placeholder="Pais"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="1">
          <b-form-group
            label="UF"
            label-for="state"
          >
            <b-form-input
              id="state"
              v-model="form.state"
              placeholder="UF"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Cidade"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="form.city"
              placeholder="Cidade"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Bairro"
            label-for="district"
          >
            <b-form-input
              id="district"
              v-model="form.district"
              placeholder="Bairro"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8">
          <b-form-group
            label="Rua"
            label-for="street"
          >
            <b-form-input
              id="street"
              v-model="form.street"
              placeholder="Rua"
            />
          </b-form-group>
        </b-col>
        <b-col md="1">
          <b-form-group
            label="N°"
            label-for="number"
          >
            <b-form-input
              id="number"
              v-model="form.number"
              placeholder="N°"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Endereço de cobrança é o mesmo ?"
          >
            <div class="d-flex">
              <b-form-checkbox
                v-model="sameAdress"
                value="1"
                class="custom-control-success"
                @change="defineSameAdress(1)"
              >
                Sim
              </b-form-checkbox>
              <b-form-checkbox
                v-model="sameAdress"
                value="0"
                class="custom-control-danger ml-2"
                @change="defineSameAdress(0)"
              >
                Não
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Complemento"
            label-for="complement"
          >
            <b-form-input
              id="complement"
              v-model="form.complement"
              placeholder="Complemento"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h4>
        Endereço de cobrança
      </h4>
      <b-row>
        <b-col md="2">
          <b-form-group
            label="CEP"
            label-for="billingZipCode"
          >
            <b-form-input
              id="billingZipCode"
              v-model="form.billingZipCode"
              placeholder="CEP"
              :disabled="sameAdress === 1"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Pais"
            label-for="billingCountry"
          >
            <b-form-input
              id="billingCountry"
              v-model="form.billingCountry"
              placeholder="Pais"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="1">
          <b-form-group
            label="UF"
            label-for="billingState"
          >
            <b-form-input
              id="billingState"
              v-model="form.billingState"
              placeholder="UF"
              :disabled="sameAdress === 1"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Cidade"
            label-for="billingCity"
          >
            <b-form-input
              id="billingCity"
              v-model="form.billingCity"
              placeholder="Cidade"
              :disabled="sameAdress === 1"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Bairro"
            label-for="billingDistrict"
          >
            <b-form-input
              id="billingDistrict"
              v-model="form.billingDistrict"
              placeholder="Bairro"
              :disabled="sameAdress === 1"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="10">
          <b-form-group
            label="Rua"
            label-for="billingStreet"
          >
            <b-form-input
              id="billingStreet"
              v-model="form.billingStreet"
              placeholder="Rua"
              :disabled="sameAdress === 1"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="N°"
            label-for="billingNumber"
          >
            <b-form-input
              id="billingNumber"
              v-model="form.billingNumber"
              placeholder="N°"
              :disabled="sameAdress === 1"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Complemento"
            label-for="billingComplement"
          >
            <b-form-input
              id="billingComplement"
              v-model="form.billingComplement"
              placeholder="Complemento"
              :disabled="sameAdress === 1"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row>
        <b-col md="6">
          <b-button
            block
            variant="danger"
          >
            Cancelar
          </b-button>
        </b-col>
        <b-col md="6">
          <b-button
            block
            variant="success"
            @click="updateSupplier()"
          >
            Enviar
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'Addfornecedores',
  data() {
    return {
      form: {
        ineternalId: '',
        tinyId: '',
        code: '',
        name: '',
        fantasyName: '',
        type: 'J',
        document: '',
        ie: '',
        rg: '',
        im: '',
        zipCode: '',
        country: 'Brasil',
        state: '',
        city: '',
        district: '',
        street: '',
        number: '',
        complement: '',
        billingZipCode: '',
        billingCountry: 'Brasil',
        billingState: '',
        billingCity: '',
        billingDistrict: '',
        billingStreet: '',
        billingNumber: '',
        billingComplement: '',
        phone: '',
        fax: '',
        cell: '',
        email: '',
        emailNfe: '',
        site: '',
        situation: 'A',
        obs: '',
        status: '',
        sent: '',
      },
      typesIe: [
        { text: 'Contribuinte', value: 'Contribuinte' },
        { text: 'Isento', value: 'Isento' },
        { text: 'Não Contribuinte', value: 'NaoContribuinte' },
      ],
      sameAdress: 0,
    }
  },
  created() {
    this.BuscarInformacoes()
  },
  methods: {
    BuscarInformacoes() {
      this.fornecedor = JSON.parse(localStorage.getItem('conveyor/edit'))
      this.form = this.fornecedor
      if (this.form.zipCode === this.form.billingZipCode) {
        this.sameAdress = 1
      }
      if (this.form.ie === 'Isento') {
        this.form.ie = { text: 'Isento', value: 'Isento' }
      } else if (this.form.ie === 'Contribuinte') {
        this.form.ie = { text: 'Contribuinte', value: 'Contribuinte' }
      } else {
        this.form.ie = { text: 'Não Contribuinte', value: 'NaoContribuinte' }
      }
    },
    defineSameAdress(value) {
      if (value === 1) {
        this.form.billingZipCode = this.form.zipCode
        this.form.billingCountry = this.form.country
        this.form.billingState = this.form.state
        this.form.billingCity = this.form.city
        this.form.billingDistrict = this.form.district
        this.form.billingStreet = this.form.street
        this.form.billingNumber = this.form.number
        this.form.billingComplement = this.form.complement
      } else {
        this.form.billingZipCode = ''
        this.form.billingCountry = 'Brasil'
        this.form.billingState = ''
        this.form.billingCity = ''
        this.form.billingDistrict = ''
        this.form.billingStreet = ''
        this.form.billingNumber = ''
        this.form.billingComplement = ''
      }
    },
    async updateSupplier() {
      const { data } = await this.$http.post('/conveyors/update', this.form)
      if (data.success) {
        this.$toast.success('Transportador atualizado com sucesso!')
        this.$router.push('/transportadores')
      } else {
        this.$toast.error('Erro ao atualizar o fornecedor')
      }
    },
  },
}
</script>
